const state = {
  isLoading: true,
  isLogged: null,
  loggedUser: null,
  loginType: null,
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  login(state, user) {
    // console.log('Called login action')
    state.isLogged = true;
    state.loggedUser = user.email;
    state.loginType = user.loginType;
  },
  logout(state) {
    // console.log('Called logout action')
    state.isLogged = false;
    state.loggedUser = null;
    state.loginType = null;
  },
};

const actions = {
  logout: ({ commit }) => commit("logout"),
  login: ({ commit }, user) => commit("login", user),
};

export default {
  state,
  mutations,
  actions,
};
