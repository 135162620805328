<template>
  <div class="auth-layout row align-content--center">
    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-card-content>
            <div class="row align--center justify--space-between">
              <language-dropdown class="app-navbar-actions__item" />
              <color-dropdown class="app-navbar-actions__item mr-4" />
            </div>
            <div class="pa-3">
              <router-view />
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageDropdown from "../components/navbar/components/dropdowns/LanguageDropdown";
import ColorDropdown from "../components/navbar/components/dropdowns/color-dropdown/ColorDropdown";
export default {
  name: "AuthLayout",
  components: { LanguageDropdown, ColorDropdown },
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(
    to right,
    var(--va-background),
    var(--va-white)
  );

  &__card {
    width: 100%;
    max-width: 700px;
  }
}
</style>
