import { createStore } from "vuex";
import app from "./modules/app";

import * as getters from "./getters";

const store = createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
  },
  state: {
    isSidebarMinimized: false,
  },
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized;
    },
  },
});

// Vue.use(VuexI18n.plugin, store)

export default store;
