import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import AuthLayout from "@/layout/auth-layout.vue";
import AppLayout from "@/layout/app-layout.vue";

const routes = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "me" },
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        meta: {
          ignoreAuth: true,
        },
        default: true,
        component: () => import("@/pages/auth/login/Login.vue"),
      },
      {
        name: "instantiate",
        path: "instantiate",
        meta: {
          ignoreAuth: true,
        },
        component: () => import("@/pages/auth/signup/Instantiate.vue"),
      },
      {
        name: "resetPassword",
        path: "resetPassword",
        meta: {
          ignoreAuth: true,
        },
        component: () =>
          import("@/pages/auth/recover-password/RecoverPassword.vue"),
        props: (route) => ({ redirect: route.query.redirect }),
      },
      {
        name: "setPassword",
        path: "setPassword/:id",
        meta: {
          ignoreAuth: true,
        },
        component: () =>
          import("@/pages/auth/recover-password/setPassword.vue"),
      },
      {
        path: "",
        redirect: { name: "login" },
      },
    ],
  },
  {
    path: "/settings",
    component: AppLayout,
    children: [
      {
        name: "users",
        path: "users",
        component: () => import("@/pages/admin/userView.vue"),
      },
      {
        name: "organizations",
        path: "organizations",
        component: () => import("@/pages/admin/orgView.vue"),
      },
      {
        name: "apps",
        path: "apps",
        component: () => import("@/pages/admin/appView.vue"),
      },
      {
        name: "access",
        path: "access",
        component: () => import("@/pages/admin/accView.vue"),
      },
      {
        name: "me",
        path: "me",
        component: () => import("@/pages/admin/meView.vue"),
        default: true,
      },
      {
        name: "wallet",
        path: "wallet",
        component: () => import("@/pages/admin/walletView.vue"),
      },
      {
        path: "",
        redirect: { name: "me" },
      },
    ],
  },
];

const router = createRouter({
  history:
    process.env.VUE_APP_ROUTER_MODE_HISTORY === "true"
      ? createWebHistory(process.env.BASE_URL)
      : createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
